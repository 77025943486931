<div class="container">
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
      <div class="cardPrincipal">
        <div class="card cardRD" style="border-radius: 15px;">
          <div class="row d-flex">
            <div class="col-xl-3 col-lg-3 d-xl-flex d-lg-flex d-md-none d-sm-none d-none d-xxs-none">
              <img src="../../../../../assets/images/logo.png" alt="Logo Ombrello Solar" style="width: 90%;">
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <h4 class="card-header ff-gotham-bold headerRD">Proposta de Seguro - Seguro Riscos Diversos</h4>
              <h5 class="card-title ff-gotham-medium subtitleRD">Equipamentos Fotovoltaicos</h5>
              <p class="card-text ff-gotham-light titleRD">
                Processo SUSEP: 15414.0002662012-18 , 15414.9013852013-62
              </p>
              <p class="card-text ff-gotham-light titleRD">
                AKAD SEGUROS S.A. - CNPJ 14.868.712/0001-31
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<hr>

<div class="container-fluid">
  <div class="row">
    <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12">
      <mat-stepper linear #stepper class="p-3 mb-3 rounded">
        <mat-step label="Cotação" [completed]="(formStep0.valid && !!entity?.proposalNo)">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="formStep0">
                  <div class="card cardCotacao pt-3 pb-3 mt-5 mb-5">
                    <div class="card-body bodyCotacao">
                      <div class="row d-flex flex-row pb-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <h6 class="title-items ff-gotham-bold">Dados Segurado:</h6>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-5">
                        <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12">
                          <label class="form-label ff-gotham-light">Nome Completo do Segurado: </label>
                          <input name="name" formControlName="name" type="text" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep0.get('name')?.errors?.['required']
                          && formStep0.get('name')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-4">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <h6 class="title-items ff-gotham-bold">Informações Equipamento:</h6>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                          <label class="form-label  ff-gotham-light">Início de Vigência</label>
                          <input type="date" [min]="todayDate" class="form-control input-principal ff-gotham-light" name="effectiveDate" formControlName="effectiveDate">
                          <div
                            class="msg-error"
                            *ngIf="formStep0.get('effectiveDate')?.errors?.['required']
                          && formStep0.get('effectiveDate')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                          <label class="form-label  ff-gotham-light">Fim de Vigência</label>
                          <input type="date" class="form-control input-principal ff-gotham-light" name="expiryDate" formControlName="expiryDate">
                          <div
                            class="msg-error"
                            *ngIf="formStep0.get('expiryDate')?.errors?.['required']
                          && formStep0.get('expiryDate')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                          <label class="form-label  ff-gotham-light">Ano de Fabricação</label>
                          <input type="text" class="form-control input-principal ff-gotham-light" name="manufactureYear" formControlName="manufactureYear" mask="0000">
                          <div
                            class="msg-error"
                            *ngIf="formStep0.get('manufactureYear')?.errors?.['required']
                          && formStep0.get('manufactureYear')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                          <label class="form-label ff-gotham-light">Valor Risco Total</label>
                          <input type="text" class="form-control input-principal ff-gotham-light" name="equipmentAmount"  formControlName="equipmentAmount" prefix="R$"  mask="0,00 || 00,00 || 000,00 || 0.000,00 || 00.000,00 || 000.000,00 || 0.000.000,00" >
                          <div
                            class="msg-error"
                            *ngIf="formStep0.get('equipmentAmount')?.errors?.['required']
                          && formStep0.get('equipmentAmount')?.touched">
                            Esse campo é obrigatório!
                          </div>
                          <!-- <label class="form-label ff-gotham-light">Potência Gerada (kwp)</label>
                          <input type="text" class="form-control input-principal ff-gotham-light" name="power" formControlName="power"> -->
                        </div>
                      </div>

                      <hr>
                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <p class="ff-gotham-bold">Deseja adicionar outras coberturas?
                          </p>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input name="lucrosCessantes" formControlName="lucrosCessantes" type="checkbox" class="form-check-input" id="lucrosCessantes" (change)="obsrvablesFormChanges()">
                          <label for="lucrosCessantes" class="form-check-label ff-gotham-light" style="margin-left: .25rem">
                            Cobertura de lucros cessantes
                          </label>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input name="alagamentos" formControlName="alagamentos" type="checkbox" class="form-check-input" id="alagamentos" (change)="obsrvablesFormChanges()">
                          <label for="alagamentos" class="form-check-label ff-gotham-light" style="margin-left: .25rem">
                            Cobertura de alagamentos
                          </label>
                        </div>
                      </div>

                    </div>

                    <div class="card-footer footerCotacao">
                      <button *ngIf="recalcular == false && !entity.id" class="btn btn-principal" (click)="quote('quick')" [disabled]="entity?.status == 'Effective'">
                        Calcular
                      </button>
                      <button *ngIf="!!entity?.id && !recalcular && !entity?.proposalNo" class="btn btn-principal" (click)="quote('full')" [disabled]="entity?.status == 'Effective'">
                        Seguir para Proposta
                      </button>
                      <button *ngIf="!!entity?.proposalNo && !!entity?.id" class="btn btn-principal" matStepperNext>
                        Seguir
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step label="Termos" [completed]="formStep1.valid && formStep1.value.termos == true">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="formStep1">
                  <div class="card mt-5 mb-5 declaracaoCard">
                    <div class="card-header headerDeclaracao">
                      <h4 class="card-title ff-gotham-bold">
                        Termos e Condições
                      </h4>
                    </div>
                    <div class="card-body bodyDeclaracao" style="overflow-y: auto; height: 25rem;">
                      <p class="card-text ff-gotham-light">
                        O segurado através desta proposta autoriza a emissão do seguro conforme dados constantes na cotação e se responsabiliza pelo pagamento do prêmio.
                      </p>
                      <p class="card-text ff-gotham-light">
                        O Segurado declara estar ciente que:
                      </p>
                      <ol>
                        <li class="ff-gotham-light">As informações foram prestadas com exatidão, boa fé e veracidade e assume integral responsabilidade, inclusive pelas não escritas de próprio punho e que tomou conhecimento prévio das Condições Gerais da Apólice;</li>
                        <li class="ff-gotham-light">Autoriza expressamente a inclusão de todos os dados e informações relacionadas ao presente seguro, assim como de todos os eventuais sinistros e ocorrências referentes ao mesmo, em banco de dados, aos quais a seguradora poderá recorrer para análise de riscos e na liquidação de processos de sinistros. Autoriza também a consulta junto à banco de dados de instituições de perfil de crédito, bem como o registro da consulta em tais instituições;</li>
                        <li class="ff-gotham-light">A AKAD Seguros se reserva ao direito de recusar esta proposta até 15 dias após o recebimento da proposta, da documentação solicitada;</li>
                        <li class="ff-gotham-light">A cobertura de seguro só se inicia no momento da entrega protocolada da proposta da AKAD Seguros.</li>
                      </ol>

                      <p class="card-text ff-gotham-light">Além dos Riscos Excluídos nas Condições Gerais, encontram-se excluídos também quaisquer danos causados aos equipamentos fotovoltaicos objeto do seguro:</p>
                      <ol>
                        <li class="ff-gotham-light">Quando não estiverem instalados e/ou em operação.</li>
                        <li class="ff-gotham-light">Quando os equipamentos estiverem instalados em edifício (s) com classe de construção inferior e/ou mista.</li>
                        <li class="ff-gotham-light">Quando os equipamentos fazem parte de uma usina solar e/ou fazenda solar que gerem energia em escala comercial (tensão superior a 380 volts).</li>
                        <li class="ff-gotham-light">Quando o local em que os equipamentos estão instalados não possuem estruturas dimensionadas para suportá-los.</li>
                        <li class="ff-gotham-light">Quando não existir um laudo técnico certificando que a estrutura do edifício/empreendimento está apta para receber o sobrepeso.</li>
                        <li class="ff-gotham-light">Quando o(s) equipamento(s) do Segurado, no(s) local(is) especificado(s) na apólice, não estiverem em área(s) devidamente cercada(s) e/ou fechada(s), sob a vigilância do Segurado.</li>
                        <li class="ff-gotham-light">Quando os equipamentos estiverem instalados em posto(s) de combustível(s) e/ou estruturas semelhantes.</li>
                      </ol>

                      <p class="card-text ff-gotham-light">A aceitação do seguro estará sujeita à análise do risco.
                        <br>
                        O registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou recomendação a sua comercialização.
                        <br>
                        O segurado poderá consultar a situação cadastral de seu corretor de seguros, no site www.susep.gov.br, por meio do número de seu registro na SUSEP, nome completo, CNPJ ou CPF.<br>
                        As condições contratuais/regulamento deste produto protocolizadas pela sociedade/entidade junto à Susep poderão ser consultadas no endereço eletrônico www.susep.gov.br, de acordo com o número de processo constante da apólice/proposta.
                      </p>
                    </div>
                    <div class="card-footer footerDeclaracao">
                      <input name="termos" formControlName="termos" type="checkbox" class="form-check-input" id="termos" >
                      <label for="termos" class="form-check-label ff-gotham-light" style="margin-left: .25rem">
                        Eu <strong>li e concordo</strong> todos os Termos e Condições.
                      </label>
                      <div
                        class="msg-error"
                        *ngIf="formStep1.get('termos')?.errors?.['required']
                          && formStep1.get('termos')?.touched">
                        Esse campo é obrigatório!
                      </div>
                    </div>
                  </div>
                  <div class="card mt-5 mb-5 cardPEP">
                    <div class="card-body bodyPEP">
                      <h5 class="card-title titlePEP pb-3 ff-gotham-bold">
                        É Pessoa Politicamente Exposta (PEP*)?
                      </h5>
                      <p class="card-subtitle ff-gotham-light">
                        *Conforme dispõe art. 4º da Circular Susep 445/2012, Pessoa Politicamente Exposta (PEP) são as pessoas que ocupam ou tenham ocupado, nos últimos 5
                        (cinco) anos, cargo político ou posição relevante em empresas públicas ou público-privadas, no brasil ou no exterior. Ainda, consideram-se seus familiares
                        pessoas relacionadas ao PPE, como pais, filhos e cônjuges. Exemplos de PPE são os diretores de empresas públicas, presidente da República, vereadores e
                        deputados.
                    </div>
                    <div class="card-footer footerPEP">
                      <input value="true" class="form-input" type="radio" id="sim" name="isPep" formControlName="isPep" style="margin-right: .75rem;">
                      <label for="sim" class="form-label ff-gotham-medium" style="margin-right: 2rem;" >Sim</label>

                      <input value="false" type="radio" id="nao" name="isPep" formControlName="isPep" style="margin-right: .75rem;">
                      <label for="nao" class="form-label ff-gotham-medium">Não</label>
                      <div
                        class="msg-error"
                        *ngIf="formStep1.controls['isPep'].invalid && formStep1.controls['isPep'].touched ">
                        Esse campo é obrigatório!
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row pb-5">
              <div class="col-12 d-flex justify-content-end">
                <button class="btn btn-principal ff-gotham-bold mt-3" matStepperNext (click)="verificaTermos()">
                  Seguir
                </button>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step  label="Dados do Segurado" [completed]="formStep2.valid && !!entity?.paymentCode">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="formStep2">
                  <div class="card cardSegurado mt-5 mb-5">
                    <div class="card-header headerSegurado">
                      <h4 class="card-title ff-gotham-bold">
                        Informações Segurado
                      </h4>
                    </div>
                    <div class="card-body bodySegurado">
                      <div class="row d-flex flex-row pb-3">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input value="PF" class="form-input" type="radio" id="pf" name="type" formControlName="type"  style="margin-right: .75rem;">
                          <label for="pf" class="form-label ff-gotham-medium" style="margin-right: 2rem;">Pessoa Física</label>

                          <input value="PJ" type="radio" id="pj" name="type" formControlName="type"  style="margin-right: .75rem;">
                          <label for="pj" class="form-label ff-gotham-medium" >Pessoa Juridica</label>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10" >
                          <label for="name" class="form-label ff-gotham-light" *ngIf="formStep2.value.type == 'PF'">Nome Completo</label>
                          <label for="empresa" class="form-label ff-gotham-light" *ngIf="formStep2.value.type == 'PJ'">Razão Social Empresa</label>
                          <input name="name" formControlName="name"  id="name" type="text" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('name')?.errors?.['required']
                            && formStep2.get('name')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7" *ngIf="formStep2.value.type == 'PF'">
                          <label for="cpf" class="form-label ff-gotham-light">CPF</label>
                          <input name="cpf" formControlName="document"  id="cpf" type="text" class="form-control input-principal ff-gotham-light" mask="000.000.000-00">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('cpf')?.errors?.['required']
                            && formStep2.get('cpf')?.touched">
                            Esse campo é obrigatório!
                          </div>
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('document')?.errors?.['invalidCpf']">
                              CPF inválido!
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7" *ngIf="formStep2.value.type == 'PF'">
                          <label for="dateOfBirth" class="form-label ff-gotham-light">Data de Nascimento</label>
                          <input name="dateOfBirth" formControlName="dateOfBirth" id="dateOfBirth" type="date" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('dateOfBirth')?.errors?.['required']
                            && formStep2.get('dateOfBirth')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>

                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <label class="form-label ff-gotham-light">Telefone</label>
                          <input name="telefone" formControlName="mobile" type="tel" class="form-control input-principal ff-gotham-light" mask="(00) 0000-0000 ||(00) 00000-0000">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('telefone')?.errors?.['required']
                            && formStep2.get('telefone')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-10 col-sm-10">
                          <label class="form-label ff-gotham-light">Email</label>
                          <input name="email" formControlName="email" type="text" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('email')?.errors?.['required']
                            && formStep2.get('email')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2" >
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7" *ngIf="formStep2.value.type == 'PJ'">

                          <label for="cnpj" class="form-label ff-gotham-light">CNPJ</label>
                          <input name="cnpj" formControlName="document"  id="cnpj" type="text" class="form-control input-principal ff-gotham-light" mask="00.000.000/0000-00">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('document')?.errors?.['required']
                            && formStep2.get('document')?.touched">
                            Esse campo é obrigatório!
                          </div>
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('document')?.errors?.['invalidCnpj']">
                              CNPJ inválido!
                          </div>
                        </div>
                      </div>

                      <hr class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                      <div class="row pb-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input formControlName="respPagamento" id="respPagamento" type="checkbox" class="form-check-l">
                          <label for="respPagamento" class="ff-gotham-medium pb-4" style="margin-left: .75rem; padding-bottom: .25rem;">O Segurado não é o responsável pelo pagamento do seguro?</label>
                        </div>
                      </div>
                      <div class="row d-flex flex-row pb-3" *ngIf="formStep2.value.respPagamento == true">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input value="PF" class="form-input" type="radio" id="pfEst" name="typeEst" formControlName="typeEst"  style="margin-right: .75rem;">
                          <label for="pfEst" class="form-label ff-gotham-medium" style="margin-right: 2rem;">Pessoa Física</label>

                          <input value="PJ" type="radio" id="pjEst" name="typeEst" formControlName="typeEst"  style="margin-right: .75rem;">
                          <label for="pjEst" class="form-label ff-gotham-medium" >Pessoa Juridica</label>
                        </div>
                      </div>
                      <div class="row pb-2" *ngIf="formStep2.value.respPagamento == true">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                          <label for="estipulante" class="form-label ff-gotham-light">Estipulante</label>
                          <input name="estipulante" formControlName="estipulante" id="estipulante" type="text" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('estipulante')?.errors?.['required']
                            && formStep2.get('estipulante')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2" *ngIf="formStep2.value.respPagamento == true">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <div *ngIf="formStep2.value.typeEst == 'PJ'">
                            <label for="cnpjEst" class="form-label ff-gotham-light">CNPJ</label>
                            <input name="cnpjEst" formControlName="cnpjEst" id="cnpjEst" type="text" class="form-control input-principal ff-gotham-light" mask="00.000.000/0000-00">
                          </div>
                          <div *ngIf="formStep2.value.typeEst == 'PF'">
                            <label for="cnpjEst" class="form-label ff-gotham-light">CPF</label>
                            <input name="cnpjEst" formControlName="cnpjEst" id="cnpjEst" type="text" class="form-control input-principal ff-gotham-light" mask="000.000.000-00">
                          </div>
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('cnpjEst')?.errors?.['required']
                            && formStep2.get('cnpjEst')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7" *ngIf="formStep2.value.respPagamento == true">
                          <label for="emailEst" class="form-label ff-gotham-light">Email</label>
                          <input name="emailEst" formControlName="emailEst" id="emailEst" type="text" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('emailEst')?.errors?.['required']
                            && formStep2.get('emailEst')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="card cardEndereco mb-5">
                    <div class="card-header headerEndereco">
                      <h4 class="card-title ff-gotham-bold">Informações de Endereço</h4>
                    </div>
                    <div class="card-body bodyEndereco">
                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <label class="form-label ff-gotham-light" for="postCode">1 - CEP Endereço do Segurado</label>
                          <input name="postCode" formControlName="postCode" id="postCode" class="form-control input-principal ff-gotham-light" mask="00000-000">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('postCode')?.errors?.['required']
                              && formStep2.get('postCode')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                          <label for="street" class="form-label ff-gotham-light">Endereço do Segurado</label>
                          <input name="street" formControlName="street" id="street" type="text" class="form-control ff-gotham-light input-principal">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('street')?.errors?.['required']
                              && formStep2.get('street')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-4 col-lg-4 col-md-10 col-sm-10">
                          <label for="number" class="form-label ff-gotham-light">Número</label>
                          <input name="number" formControlName="number" id="number" type="text" class="form-control input-principal ff-gotham-light" mask="0000000000">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('number')?.errors?.['required']
                            && formStep2.get('number')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-10 col-sm-10">
                          <label for="complement" class="form-label ff-gotham-light">Complemento</label>
                          <input name="complement" formControlName="complement" id="complement" type="text" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('complement')?.errors?.['required']
                            && formStep2.get('complement')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex flex-column">
                          <label class="form-label ff-gotham-light" for="state">UF</label>
                          <select id="state" name="state" formControlName="state" class="custom-select input-principal ff-gotham-light p-2">
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                            <option value="EX">Estrangeiro</option>
                          </select>
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('state')?.errors?.['required']
                            && formStep2.get('state')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                          <label class="form-label ff-gotham-light" for="city">Cidade</label>
                          <input name="city" formControlName="city" id="city" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('city')?.errors?.['required']
                            && formStep2.get('city')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                          <label class="form-label ff-gotham-light" for="suburb">Bairro</label>
                          <input name="suburb" formControlName="suburb" id="suburb" class="form-control input-principal input-suburb ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('suburb')?.errors?.['required']
                            && formStep2.get('suburb')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>

                      <hr class="col-xl-12 col-lg-12 col-md-12 col-sm-12">

                      <div class="row pb-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <input name="checkEndereco" id="checkEndereco" type="checkbox" class="form-check-l" [formControl]="mesmoEndereco">
                          <label for="checkEndereco" class="ff-gotham-medium pb-4" style="margin-left: .75rem; padding-bottom: .25rem;">O endereço de cobrança é o mesmo endereço do Segurado?</label>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-5 col-lg-5 col-md-7 col-sm-7">
                          <label class="form-label ff-gotham-light" for="billingPostCode">2- CEP Endereço Cobrança</label>
                          <input name="billingPostCode" formControlName="billingPostCode" id="billingPostCode" class="form-control input-principal ff-gotham-light" mask="00000-000">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('billingPostCode')?.errors?.['required']
                            && formStep2.get('billingPostCode')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                          <label for="billingStreet" class="form-label ff-gotham-light">Endereço de Cobrança</label>
                          <input name="billingStreet" formControlName="billingStreet" id="billingStreet" type="text" class="form-control ff-gotham-light input-principal">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('billingStreet')?.errors?.['required']
                            && formStep2.get('billingStreet')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-4 col-lg-4 col-md-10 col-sm-10">
                          <label for="billingNumber" class="form-label ff-gotham-light">Número</label>
                          <input name="billingNumber" formControlName="billingNumber"  id="billingNumber" type="text" class="form-control input-principal ff-gotham-light" mask="0000000000">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('billingNumber')?.errors?.['required']
                            && formStep2.get('billingNumber')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-10 col-sm-10">
                          <label for="billingComplement" class="form-label ff-gotham-light">Complemento</label>
                          <input name="billingComplement" formControlName="billingComplement" id="billingComplement" type="text" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('billingComplement')?.errors?.['required']
                            && formStep2.get('billingComplement')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex flex-column">
                          <label class="form-label ff-gotham-light" for="billingState">UF</label>
                          <select id="billingState"  name="billingState" formControlName="billingState" class="custom-select input-principal ff-gotham-light p-2">
                            <option value="AC">Acre</option>
                            <option value="AL">Alagoas</option>
                            <option value="AP">Amapá</option>
                            <option value="AM">Amazonas</option>
                            <option value="BA">Bahia</option>
                            <option value="CE">Ceará</option>
                            <option value="CE">Ceará</option>
                            <option value="DF">Distrito Federal</option>
                            <option value="ES">Espírito Santo</option>
                            <option value="GO">Goiás</option>
                            <option value="MA">Maranhão</option>
                            <option value="MT">Mato Grosso</option>
                            <option value="MS">Mato Grosso do Sul</option>
                            <option value="MG">Minas Gerais</option>
                            <option value="PA">Pará</option>
                            <option value="PB">Paraíba</option>
                            <option value="PR">Paraná</option>
                            <option value="PE">Pernambuco</option>
                            <option value="PI">Piauí</option>
                            <option value="RJ">Rio de Janeiro</option>
                            <option value="RN">Rio Grande do Norte</option>
                            <option value="RS">Rio Grande do Sul</option>
                            <option value="RO">Rondônia</option>
                            <option value="RR">Roraima</option>
                            <option value="SC">Santa Catarina</option>
                            <option value="SP">São Paulo</option>
                            <option value="SE">Sergipe</option>
                            <option value="TO">Tocantins</option>
                            <option value="EX">Estrangeiro</option>
                          </select>
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('billingState')?.errors?.['required']
                            && formStep2.get('billingState')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                          <label class="form-label ff-gotham-light" for="billingCity">Cidade</label>
                          <input name="billingCity" formControlName="billingCity"  id="billingCity" class="form-control input-principal ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('billingCity')?.errors?.['required']
                            && formStep2.get('billingCity')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2">
                        <div class="col-10">
                          <label class="form-label ff-gotham-light" for="billingSuburb">Bairro</label>
                          <input name="billingSuburb" formControlName="billingSuburb" id="billingSuburb" class="form-control input-principal input-billingSuburb ff-gotham-light">
                          <div
                            class="msg-error"
                            *ngIf="formStep2.get('billingSuburb')?.errors?.['required']
                            && formStep2.get('billingSuburb')?.touched">
                            Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-12 div col-lg-12 col-md-12 col-sm-12  d-flex justify-content-end">
                      <button
                        class="btn btn-principal ff-gotham-bold mt-3"
                        style="margin-right: 1.5rem;"
                        matStepperPrevious>Voltar</button>
                      <button
                      *ngIf="entity?.status == '0'"
                        class="btn btn-principal ff-gotham-bold mt-3"
                        (click)="bind()"
                      >
                        Ir para Pagamento
                      </button>
                      <button *ngIf="entity?.status == 'Effective' || entity?.status == 'NotEffective'" class="btn btn-principal ff-gotham-bold mt-3" (click)="modal.open()" matStepperNext>
                        Seguir
                      </button>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step  label="Pagamento" [completed]="(formStep3.valid && entity?.status == 'Effective') || entity?.status == 'Effective'">
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form [formGroup]="formStep3">
                  <div class="card cardPagamento mt-5 mb-5">
                    <div class="card-header headerPagamento">
                      <h4 class="card-title ff-gotham-bold">
                        Forma de Pagamento
                      </h4>
                    </div>
                    <div class="card-body bodyPagamento">
                      <div class="row pb-5">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
                          <label for="tipoPagamento" class="form-label ff-gotham-medium">Escolha a forma de pagamento:</label>
                          <select name="tipoPagamento" formControlName="tipoPagamento" id="tipoPagamento" class="custom-select input-principal ff-gotham-light p-2" #pagamento>
                            <option value="">- SELECIONE -</option>
                            <option value="boleto">BOLETO</option>
                            <option value="credito">CARTÃO DE CRÉDITO</option>
                          </select>
                          <div
                            class="msg-error"
                            *ngIf="formStep3.get('tipoPagamento')?.errors?.['required']
                            && formStep3.get('tipoPagamento')?.touched">
                              Esse campo é obrigatório!
                          </div>
                        </div>
                      </div>
                      <div class="row pb-2 mb-3" *ngIf="formStep3.value.tipoPagamento == 'credito' || formStep3.value.tipoPagamento == 'boleto'">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <div class="card cardBoleto">
                            <div class="card-header headerBoleto">
                              <p class="card-subtitle ff-gotham-medium" *ngIf="formStep3.value.tipoPagamento == 'boleto'">Pagamento por Boleto</p>
                              <p class="card-subtitle ff-gotham-medium" *ngIf="formStep3.value.tipoPagamento == 'credito'">Pagamento por Cartão de Crédito</p>
                            </div>
                            <div class="card-body bodyCredito">
                              <div class="row pb-2" *ngIf="formStep3.value.tipoPagamento == 'credito'">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" >
                                  <label class="form-label ff-gotham-light">Titular do Cartão</label>
                                  <input name="titularCartao" formControlName="name" type="text" class="form-control input-principal ff-gotham-light">
                                  <div
                                    class="msg-error"
                                    *ngIf="formStep3.get('titularCartao')?.errors?.['required']
                                  && formStep3.get('titularCartao')?.touched">
                                    Esse campo é obrigatório!
                                  </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                  <label for="cpfTitular" class="form-label ff-gotham-light">CPF do Titular</label>
                                  <input name="cpfTitular" formControlName="identity" id="cpfTitular" type="text" class="form-control input-principal ff-gotham-light" mask="000.000.000-00">
                                  <div
                                    class="msg-error"
                                    *ngIf="formStep3.get('cpfTitular')?.errors?.['required']
                                  && formStep3.get('cpfTitular')?.touched">
                                    Esse campo é obrigatório!
                                  </div>
                                </div>
                              </div>
                              <div class="row pb-2" *ngIf="formStep3.value.tipoPagamento == 'credito'">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                  <label for="numCartao" class="form-label ff-gotham-light">Número do Cartão</label>
                                  <input name="numCartao" formControlName="number" id="numCartao" type="text" class="form-control input-principal ff-gotham-light" mask="0000 0000 0000 0000">
                                  <div
                                    class="msg-error"
                                    *ngIf="formStep3.get('numCartao')?.errors?.['required']
                                  && formStep3.get('numCartao')?.touched">
                                    Esse campo é obrigatório!
                                  </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                  <label for="validadeCartao" class="form-label ff-gotham-light">Data de Validade do Cartão</label>
                                  <input name="validadeCartao" formControlName="expiration" id="validadeCartao" type="text" class="form-control input-principal ff-gotham-light" mask="00/00">
                                  <div
                                    class="msg-error"
                                    *ngIf="formStep3.get('validadeCartao')?.errors?.['required']
                                  && formStep3.get('validadeCartao')?.touched">
                                    Esse campo é obrigatório!
                                  </div>
                                </div>
                              </div>
                              <div class="row pb-2">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12" *ngIf="formStep3.value.tipoPagamento == 'credito'">
                                  <label for="cvv" class="form-label ff-gotham-light">Código de Segurança do Cartão (CVV)</label>
                                  <input name="cvv" formControlName="cvv" id="cvv" type="text" class="form-control input-principal ff-gotham-light" mask="000">
                                  <div
                                    class="msg-error"
                                    *ngIf="formStep3.get('cvv')?.errors?.['required']
                                  && formStep3.get('cvv')?.touched">
                                    Esse campo é obrigatório!
                                  </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
                                  <label for="numParcelasCartao" class="form-label ff-gotham-light">Número de parcelas</label>
                                  <select name="numParcelasCartao" formControlName="installment" id="numParcelasCartao" type="text" class="custom-select input-principal ff-gotham-light p-2 mb-2">
                                    <option [value]="i+1" *ngFor="let parcela of parcelas; let i = index">{{i + 1}}x de R${{parcela}} sem juros</option>
                                  </select>
                                  <div
                                    class="msg-error"
                                    *ngIf="formStep3.get('numParcelasCartao')?.errors?.['required']
                                  && formStep3.get('numParcelasCartao')?.touched">
                                    Esse campo é obrigatório!
                                  </div>
                                  <small class="ff-gotham-light">*Limite mínimo de R$100,00 por parcela</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row pb-5">
              <div class="col-12 d-flex justify-content-end">
                <button
                  class="btn btn-principal ff-gotham-bold mt-3"
                  style="margin-right: 1.5rem;"
                  matStepperPrevious>Voltar</button>
                <button *ngIf="entity?.status == 'NotEffective'" class="btn btn-principal ff-gotham-bold mt-3" (click)="payment()">
                  Realizar Pagamento
                </button>
                <button *ngIf="entity?.status == 'Effective'" class="btn btn-principal ff-gotham-bold mt-3" matStepperNext>
                  Seguir
                </button>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step label="Finalizado!">
          <div class="container mt-5 mb-5">
            <div class="row mt-5">
              <p class="ff-gotham-bold mt-5" style="font-size:24px;">Sua contratação foi realizada com sucesso!</p>
              <div class="card card-apolice col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5">
                <div class="card-body">
                  <div class="row pb-2">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 d-inline-flex justify-content-center align-items-center">
                      <img src="../../../../../assets/images/apolice.png" style="width: 50%;">
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 d-flex flex-column justify-content-center pt-2">
                      <p class="ff-gotham-medium">Número da Apólice</p>
                      <p class="ff-gotham-light">{{entity?.certificate || '--'}}</p>
                    </div>
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 d-flex  flex-column justify-content-center align-items-center">
                      <button (click)="service.getCertificate(entity.proposalNo, false, 'apólice.pdf')" class="btn btn-principal d-inline-flex justify-content-center align-items-center mb-1" style="width: 100%; font-size:18px;">
                        <mat-icon style="margin-right: 0.5rem;">file_download</mat-icon>
                        Baixar Apólice
                      </button>
                      <button matTooltip="Certificado sem o preço do seguro" (click)="service.getCertificate(entity.proposalNo, true, 'certificado.pdf')" class="btn btn-principal d-inline-flex justify-content-center align-items-center" style="width: 100%; font-size:18px;">
                        <mat-icon style="margin-right: 0.5rem;">file_download</mat-icon>
                        Baixar Certificado
                      </button>
                    </div>
                  </div>
                  <div class="row pb-2" *ngIf="this.entity.payment?.payModeCode == '70' || this.entity.payment?.payModeCode == '304'">
                    <hr>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-column align-items-start justify-content-center">
                      <p class="ff-gotham-bold">Boletos:</p>
                      <a class="ff-gotham-medium btn d-flex justify-content-center linkBoleto" target="_blank" [href]="installment.billetUrl" *ngFor="let installment of this.entity.payment?.installments; let i = index"><mat-icon>receipt_long</mat-icon> Baixar Boleto ({{i+ 1}}ª Parcela)</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-step>
      </mat-stepper>
    </div>

    <div class="col-xl-4 col-lg-4 col-md-8 col-sm-12">
      <div class="card" style="border-left-color: #F5CF7A">
        <div class="card-body">
          <div class="row" *ngIf="stepper.selectedIndex != 0">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <p class="ff-gotham-light">Nº da Cotação:</p>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> {{ entity?.proposalNo }}</p>
            </div>
          </div>
          <div class="row" *ngIf="stepper.selectedIndex != 0">
            <div class="col xl-5 col-lg-5 col-md-12 col-sm-12">
              <p class="ff-gotham-light">Vigência do Seguro:</p>
            </div>
            <div class="col xl-7 col-lg-7 col-md-12 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium">
                {{ service.ajustDateView(entity?.effectiveDate) }} - {{ service.ajustDateView(entity?.expiryDate) }}
              </p>
            </div>
          </div>
          <div class="row" >
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-light">Valor em Risco:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ formStep0.value.equipmentAmount }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-4">
        <div class="card-body">
          <div class="row">
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-bold">Prêmio Líquido:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ maskValores(entity?.netPremium)}}</p>
            </div>
          </div>
          <hr style="border-style:dotted">
          <div class="row">
            <div class="col xl-10 col-lg-10 col-md-10 col-sm-12">
              <p class="ff-gotham-bold">Coberturas: </p>
              <div class="coberturas" *ngFor="let item of getCoverages()">
                <p class="ff-gotham-medium"> {{ item.coverageName }}</p>
              </div>
            </div>
            <!-- <div class="col xl-4 col-lg-4 col-md-4 col-sm-12 d-flex flex-column align-items-end">
              <p class="ff-gotham-bold">Valor(R$): </p>
              <div class="coberturas" *ngFor="let item of list">
                <p class="ff-gotham-medium pb-1"> {{item.duePremium}}</p>
                 <br>
              </div>
            </div>-->
          </div>
          <hr style="border-style:dotted">
          <div class="row">
            <div class="col xl-12 col-lg-12 col-md-12 col-sm-12">
              <p class="ff-gotham-bold">Taxas e Impostos:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-light"> IOF</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ maskValores(entity?.vat) }}</p>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12">
              <p class="ff-gotham-bold">Prêmio Total:</p>
            </div>
            <div class="col xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
              <p class="ff-gotham-medium"> R$ {{ maskValores(entity?.premium) }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- *ngIf="!!entity?.proposalNo" -->
      <div class="row mt-4" *ngIf="!!entity?.proposalNo">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <button
            class="btn btn-secundario expandir ff-gotham-bold d-inline-flex justify-content-center align-items-center"
            [disabled]="formStep1.invalid"
            (click)="service.getCartaCotacao(entity.proposalNo)">
            <mat-icon>file_download</mat-icon>
            Baixar Carta de Cotação
          </button>
        </div>
      </div>
      <div class="row mt-4" *ngIf="!!entity?.paymentCode">
        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 p-md-1 p-lg-1">
          <label class="form-label ff-gotham-medium" style="font-size: 16px">Link de pagamento:</label>
          <input [attr.disabled]="!!entity.paymentCode" name="linkProposta" class="form-control input-principal ff-gotham-light" [(ngModel)]="linkPagamento" [value]="linkPagamento" disabled>
        </div>
        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 pt-2">
            <button class="btn btn-secundario d-inline-flex justify-content-center ff-gotham-bold" style=" margin-top: 2rem; width: 100%;" (click)="copyLink()" mat-stroked-button>
              <mat-icon style="margin-right: 1rem;">content_copy</mat-icon>
              Copiar
            </button>
        </div>
      </div>
      <div class="row mt-4" *ngIf="paymentStep == true">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
          <label class="form-label ff-gotham-medium">Enviar Link de Pagamento:</label>
          <input name="linkPagamento" class="form-control input-principal ff-gotham-light" value="link aqui" disabled>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
          <button class="btn btn-secundario d-inline-flex justify-content-center ff-gotham-bold" style=" margin-top: 2rem; width: 100%;">
            <mat-icon style="margin-right: 1rem;">content_copy</mat-icon>
            Copiar
          </button>
        </div>
      </div>

    </div>
  </div>
</div>


<dg-modal size="lg" #modal>
    <div modal-header>
      <h4 class="ff-gotham-medium">Resumo</h4>
    </div>
    <div modal-body>
      <p class="subtitle ff-gotham-medium"> Por favor, verifique se os dados preenchidos anteriormente estão corretos:</p>
      <hr>
      <p class="ff-gotham-medium">Informações do seguro:</p>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Valor em risco total:</p>
          <p class="ff-gotham-light">R$ {{ formStep0.value.equipmentAmount ||'' }}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Vigência do seguro:</p>
          <p class="ff-gotham-light">{{ service.ajustDateView(entity?.effectiveDate) }} - {{ service.ajustDateView(entity?.expiryDate) }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Prêmio líquido:</p>
          <p class="ff-gotham-light">R$ {{ maskValores(entity?.netPremium || '')}}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">IOF:</p>
          <p class="ff-gotham-light">R$ {{ maskValores(entity?.vat || '')}}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Prêmio total:</p>
          <p class="ff-gotham-light">R$ {{ maskValores(entity?.premium || '') }}</p>
        </div>

      </div>
      <br>
      <p class="ff-gotham-medium">Informações do segurado:</p>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Nome:</p>
          <p class="ff-gotham-light">{{ formStep0.value.name }}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Documento:</p>
          <p class="ff-gotham-light">{{ maskDocument(formStep2.value.document || '') }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12" *ngIf="formStep2.value.type == 'PF'">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Data de nascimento:</p>
          <p class="ff-gotham-light">{{ maskDate(formStep2.value.dateOfBirth) }}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">E-mail:</p>
          <p class="ff-gotham-light">{{ formStep2.value.email }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12" *ngIf="formStep2.value.type == 'PF'">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Telefone:</p>
          <p class="ff-gotham-light">{{ maskMobile(formStep2.value.mobile || '') }}</p>
        </div>
      </div>
      <br>
      <p class="ff-gotham-medium">Endereço do segurado:</p>
      <div class="row">
        <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">CEP:</p>
          <p class="ff-gotham-light">{{ formStep2.value.postCode }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Rua:</p>
          <p class="ff-gotham-light">{{ formStep2.value.street }}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Número:</p>
          <p class="ff-gotham-light">{{ formStep2.value.number }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Complemento:</p>
          <p class="ff-gotham-light">{{ formStep2.value.complement || 'Não informado'}}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">UF:</p>
          <p class="ff-gotham-light">{{ formStep2.value.state }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Cidade:</p>
          <p class="ff-gotham-light">{{ formStep2.value.city }}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Bairro:</p>
          <p class="ff-gotham-light">{{ formStep2.value.suburb }}</p>
        </div>
      </div>
      <br>
      <p class="ff-gotham-medium">Informações do estipulante:</p>
      <div class="row">
        <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Estipulante:</p>
          <p class="ff-gotham-light">{{ formStep2.value.estipulante || 'Não informado' }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Documento:</p>
          <p class="ff-gotham-light">{{ maskDocument(formStep2.value.cnpjEst || '') || 'Não informado' }}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">E-mail:</p>
          <p class="ff-gotham-light">{{ formStep2.value.emailEst || 'Não informado' }}</p>
        </div>
      </div>
      <br>
      <p class="ff-gotham-medium">Endereço de cobrança:</p>
      <div class="row">
        <div class="d-flex flex-row col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">CEP:</p>
          <p class="ff-gotham-light">{{ formStep2.value.billingPostCode }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Rua:</p>
          <p class="ff-gotham-light">{{ formStep2.value.billingStreet }}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Número:</p>
          <p class="ff-gotham-light">{{ formStep2.value.billingNumber }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Complemento:</p>
          <p class="ff-gotham-light">{{ formStep2.value.billingComplement || 'Não informado'}}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">UF:</p>
          <p class="ff-gotham-light">{{ formStep2.value.billingState }}</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Cidade:</p>
          <p class="ff-gotham-light">{{ formStep2.value.billingCity }}</p>
        </div>
        <div class="d-flex flex-row col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <p class="ff-gotham-medium" style="padding-right: 0.4rem;">Bairro:</p>
          <p class="ff-gotham-light">{{ formStep2.value.billingSuburb }}</p>
        </div>
      </div>
    </div>
    <div modal-footer>
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-end">
            <button class="btn btn-secundario" style="margin-right: 2rem; width:50%" (click)="cancelar()">Cancelar</button>
            <button class="btn btn-principal" (click)="modal.close()">Confirmar</button>
          </div>
        </div>
    </div>
</dg-modal>


<div class="loader" *ngIf="showLoader">
  <div class="content-loader">
    <mat-spinner></mat-spinner>
  </div>
</div>

